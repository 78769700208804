import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import LocationOffIcon from '@mui/icons-material/LocationOff'
import LocationSearchingIcon from '@mui/icons-material/LocationSearching'
import { Box, IconButton, ListItem, Tooltip, Typography } from "@mui/material"
import { isNull } from 'lodash'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom'
import RelativeTime from "../../../helper/RelativeTime"
import { ApplicationState } from "../../../store"
import { getSafetyStatusText, SafetyStatus } from "../../../store/Entry"
import { getEventTimeZone, getMeasurementUnit, MeasurementType } from "../../../store/Event"
import { FlexBox } from "../../@Global/FlexBox"
import { HazardIcon } from "./Images/HazardIcon"
import { amber, blue, getSafetyStatusColor, greenLight, HazardEvent, isHighG, redLight, yellow } from "./Models"
import { SummaryRow } from './SummaryColumn'

type Props = {
    hazard: HazardEvent
    //    entry: Entry
    stageNumber: number
    style: React.CSSProperties
    timeZoneOffset: number
    onDelete: (h: number) => void
    relativeTime: boolean
    onHistoryToggle: (id: number, x: number, y: number) => void
}

export const HazardsRow: React.FunctionComponent<Props> = ({ hazard, style, stageNumber, onDelete, relativeTime, onHistoryToggle }) => {
    const selectedEvent = useSelector((state: ApplicationState) => state.event.selectedEvent)
    const entry = useSelector((state: ApplicationState) => state.entry.entries.find(x => x.entryId == hazard.entryId))

    const [hover, setHover] = useState<boolean>(false)

    const navigate = useNavigate()
    const rowHeight = 50

    if (!entry) return null

    const getLeftSideElement = () => {
        let background = getSafetyStatusColor(hazard.hazardType, hazard.maxGforce, 'dark')
        let iconBackground = getSafetyStatusColor(hazard.hazardType, hazard.maxGforce)
        let iconColor = '#000'

        if (hazard.hazardType == SafetyStatus.NoHazard && isNull(hazard.fiaAlarm)) {
            iconBackground = '#aaa'
            background = 'transparent'
        }

        return (
            <Box style={{
                background: background,
                height: rowHeight,
                minWidth: 52,
                borderRadius: '6px 0 0 6px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 0,
                marginRight: 10
            }}>
                <HazardIcon background={iconBackground} foreground={iconColor} style={{ width: 26, height: 26 }} />
            </Box>
        )
    }

    const getLatestSosTime = () => {
        if (hazard.manSostime == null && hazard.autoSostime == null)
            return undefined
        if (hazard.manSostime != null && hazard.autoSostime == null)
            return hazard.manSostime
        if (hazard.manSostime == null && hazard.autoSostime != null)
            return hazard.autoSostime
        else if (hazard.manSostime != null && hazard.autoSostime != null)
            return DateTime.fromISO(hazard.manSostime).toMillis() < DateTime.fromISO(hazard.autoSostime).toMillis()
                ? hazard.autoSostime : hazard.manSostime
    }

    const latestSosTime = getLatestSosTime()

    return (
        <ListItem
            key={hazard.hazardEventId}
            button
            onClick={(e) => {
                if (!hover)
                    onHistoryToggle(hazard.hazardEventId, e.pageX, e.pageY)
            }}
            component="div"
            sx={{
                ...style,
                padding: '0px 8px',
                width: '100%',
                borderRadius: '6px',
                border: '#6b6b6b 1px solid',
                height: '52px',
                paddingLeft: 0
            }}
        >
            {getLeftSideElement()}
            <FlexBox style={{
                width: '100%',
                height: rowHeight,
                alignItems: 'center'
            }}>
                <Box style={{ minWidth: 44, flex: 1, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    <Typography style={{ width: 'max-content' }} variant={'h6'}>{entry.identifier}</Typography>
                </Box>
                <Box sx={{ width: 56, textAlign: 'center' }}>
                    <SummaryRow hazard={hazard} />
                    {hazard.badLocation && <Tooltip placement='left' title={'GPS Bad'}>
                        <LocationOffIcon color='error' fontSize='small' />
                    </Tooltip>}
                </Box>
                <FlexBox column style={{ width: 136, textAlign: 'center' }}>
                    <Box style={{ textAlign: 'center' }}>
                        SS{stageNumber} / {hazard.hazardDistance}{getMeasurementUnit(selectedEvent, MeasurementType.Length)}
                    </Box>
                    <Box style={{ textAlign: 'center' }}>
                        <Typography variant='button' style={{ color: getSafetyStatusColor(hazard.hazardType, hazard.maxGforce) }}>
                            {getSafetyStatusText(hazard.hazardType, hazard.maxGforce)}
                        </Typography>

                    </Box>
                </FlexBox>
                <Box style={{ width: 78, textAlign: 'center' }}>
                    {hazard.hazardTime
                        && <FlexBox column>
                            <Typography variant='subtitle1' style={{
                                color: isHighG(hazard.maxGforce) ? amber : !isNull(hazard.fiaAlarm) ? blue : yellow
                            }}>
                                {DateTime.fromISO(hazard.hazardTime.toString()).toFormat('HH:mm:ss')}
                            </Typography>
                            {relativeTime && <Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} variant="caption" color="textSecondary">
                                {hazard.earliestTime && <RelativeTime time={DateTime.fromISO(hazard.hazardTime.toString(), { zone: getEventTimeZone(selectedEvent) })} />}
                            </Typography>}
                        </FlexBox>}
                </Box>
                <Box style={{ width: 78, textAlign: 'center' }}>
                    {latestSosTime
                        && <FlexBox column>
                            <Typography variant='subtitle1' style={{ color: redLight }}>
                                {DateTime.fromISO(latestSosTime).toFormat('HH:mm:ss')}
                            </Typography>
                            {relativeTime && <Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} variant="caption" color="textSecondary">
                                {latestSosTime && <RelativeTime time={DateTime.fromISO(latestSosTime, { zone: getEventTimeZone(selectedEvent) })} />}
                            </Typography>}
                        </FlexBox>}
                </Box>
                <Box style={{ width: 78, textAlign: 'center' }}>
                    {hazard.oktime
                        && <FlexBox column>
                            <Typography variant='subtitle1' style={{ color: greenLight }}>
                                {DateTime.fromISO(hazard.oktime).toFormat('HH:mm:ss')}
                            </Typography>
                            {relativeTime && <Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} variant="caption" color="textSecondary">
                                {hazard.oktime && <RelativeTime time={DateTime.fromISO(hazard.oktime.toString(), { zone: getEventTimeZone(selectedEvent) })} />}
                            </Typography>}
                        </FlexBox>}
                </Box>
                <FlexBox style={{ textAlign: 'center', width: 80 }}>
                    <IconButton
                        onMouseEnter={() => { setHover(true) }}
                        onMouseLeave={() => { setHover(false) }}
                        style={{ padding: 8 }}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            navigate(`/events/${selectedEvent?.eventId}/map?lat=${hazard.lat}&lng=${hazard.long}`)
                        }}
                        size="large">
                        <LocationSearchingIcon />
                    </IconButton>
                    <IconButton
                        onMouseEnter={() => { setHover(true) }}
                        onMouseLeave={() => { setHover(false) }}
                        style={{ padding: 8 }}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            onDelete(hazard.hazardEventId)
                        }}
                        size="large">
                        <HighlightOffIcon />
                    </IconButton>
                </FlexBox>
            </FlexBox>
        </ListItem>
    )
}
