import { Action, Reducer } from 'redux'
import { AppThunkAction } from '.'
import { Person, VehicleType } from './Entry'

export enum TimingStatus {
    Completed = 0,
    Running,
    Expected,
    DNS,
    DNF,
    Problem
}

export enum TimeControlSource {
    Unknown = 0,
    Coordinate = 1,
    ButtonPress = 2,
    Tablet = 3,
    Beam = 4
}

export interface StageTime {
    stageTimeId: number
    locationGroupId: number
    startOrder: number
    classification?: number
    status: TimingStatus
    identifier: string
    driver: Person
    navigator: Person
    make: string
    classId?: number
    stageLength?: number
    jumpStart?: boolean
    lateStart: boolean
    averageSpeed?: number
    maxSpeed?: number
    maxSpeedDistance?: number
    unitStartRequiredTime?: string
    unitStartActualTime?: string
    zeroSpeedStartTime?: string
    beamStartRequiredTime?: string
    beamStartActualTime?: string
    finishTime?: string
    unitFinishTime?: string
    beamFinishTime?: string
    stageTime?: number
    unitStageActualTime?: number
    unitStageRequiredTime?: number
    unitStageZeroTime?: number
    beamStageRequiredTime?: number
    beamStageActualTime?: number
    unitBeamStageTime?: number
    transitStartTime?: string
    transitFinishTime?: string
    type: VehicleType
    diffPrev: string
    diffFirst: string
    penaltyTime: number
    offset?: number
}

export interface ControlTime {
    controlTimeId: number
    locationPointId: number
    entryId: number
    buttonTime?: string
    officialTime?: string
    lastUpdatedTime: string
    source: TimeControlSource
}

export interface BeamTime {
    beamTimeId: number
    beamSerialNumber: number
    unitSerialNumber: number
    isFinish: boolean
    beamTime: string
    assignedEntryId?: number
    gpsTime?: string
    gpsEntryId?: number
}

export interface BeamTabletTime {
    beamTabletTimeId: number
    vehicleIdentifier: string
    stageNumber?: number
    beamTime: string
    entryId: number
    vehicleBeamFinish?: string
    vehicleGpsFinish?: string
    sentToResults: boolean
    sentToResultsTimestamp?: string
    sentToResultsUser: string
    difference: boolean
    isFinish: boolean
}

export const getTimeControlSourceText = (t: TimeControlSource) => {
    switch (t) {
        default: return TimeControlSource[t]
    }
}

export const getTimingStatusText = (t: TimingStatus) => {
    switch (t) {
        default: return TimingStatus[t]
    }
}

export interface Speeding {
    speedingId: number
    identifier: string
    driver: Person
    navigator: Person
    locationGroupId: number
    maximumSpeed: number
    time: number
    receivedTime: string
    recordedTime: string
    lat: number
    long: number
    distance: number
    isTransit: boolean
    speedLimit: number
    penaltyTime: number
    penaltyActive: boolean
    gpsSpeed: number
    speedChangePositive: number
    speedChangeNegative: number
    minSatellites: number
    accuracy: number
    stages: number[]
    speedingCaptures: SpeedingCapture[]
}

export interface SpeedingCapture {
    speedingCaptureId: number
    timestamp: string
    latitude: number
    longitude: number
    speed: number
    bearing: number
    satellites: number
    accuracy: number
    speedChange: number
    inCooldown: boolean
}

export interface TimesState {
    beamTimesLoading: boolean
    beamTabletTimesLoading: boolean
}

export interface SplitTimesModel {
    startOrder: number
    stageTimeId: number
    entryId: number
    identifier: string
    classId: number | null
    stageTime: number
    driver: Person
    splits: SplitModel[]
    splitPoints: number[]
    status: TimingStatus
}

export interface SplitModel {
    distance: number
    splitTime: number
    splitNumber: number | null
    speed: number
    splitTimeId: number
}

const unloaded: TimesState = {
    beamTimesLoading: false,
    beamTabletTimesLoading: false
}

interface SetBeamTimesLoading { type: 'SET_BEAM_TIMES_LOADING', value: boolean }
interface SetBeamTabletTimesLoading { type: 'SET_BEAM_TABLET_TIMES_LOADING', value: boolean }

export type KnownAction = SetBeamTimesLoading | SetBeamTabletTimesLoading

export const timesActionCreators = {
    setBeamTimesLoading: (value: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SET_BEAM_TIMES_LOADING', value
        })
    },
    setBeamTabletTimesLoading: (value: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SET_BEAM_TABLET_TIMES_LOADING', value
        })
    },
}

export const reducer: Reducer<TimesState> = (state: TimesState | undefined, incomingAction: Action): TimesState => {
    if (state === undefined) return unloaded
    const action = incomingAction as KnownAction

    switch (action.type) {
        case 'SET_BEAM_TIMES_LOADING':
            return {
                ...state,
                beamTimesLoading: action.value
            }
        case 'SET_BEAM_TABLET_TIMES_LOADING':
            return {
                ...state,
                beamTabletTimesLoading: action.value
            }
        default:
            return state
    }
}