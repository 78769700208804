import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Dispatch } from 'redux'
import LazyRoute from './LazyRoute'
import { EventTable } from './components/Events/EventsTable'
import Layout from './components/Home/Layout'
import ForgotPassword from './components/Identity/ForgotPassword'
import Login from './components/Identity/Login'
import { RequireAuth } from './components/Identity/RequireAuth'
import ResetPassword from './components/Identity/ResetPassword'
import { Unauthorized } from './components/Identity/Unauthorized'
import { PubNubContainer } from './helper/PubNubContainer'
import { ApplicationState } from './store'
import { Admin, Basic, CompanyEditor } from './store/Account'
import { eventActionCreators } from './store/Event'
import { identityActionCreators } from './store/Identity'
import { mapActionCreators } from './store/Map'

const AccountsPage = React.lazy(() => import('./components/Accounts/AccountsPage'))
const AccountSettings = React.lazy(() => import('./components/Identity/AccountSettings').then(module => ({ default: module.AccountSettings })))
const PreEventForm = React.lazy(() => import('./components/PreEventForm').then(module => ({ default: module.PreEventForm })))
const MessageRadarPage = React.lazy(() => import('./components/Metrics/MessageRadar/MessageRadarPage').then(module => ({ default: module.MessageRadarPage })))
const DetailsPage = React.lazy(() => import('./components/Events/Details/DetailsPage'))
const Equipment = React.lazy(() => import('./components/Equipment/Equipment').then(module => ({ default: module.Equipment })))
const EntriesPage = React.lazy(() => import('./components/Events/Entries/EntriesPage').then(module => ({ default: module.EntriesPage })))
const ItineraryPage = React.lazy(() => import('./components/Events/Itinerary/ItineraryPage').then(module => ({ default: module.ItineraryPage })))
const ManagePage = React.lazy(() => import('./components/Events/Manage/ManagePage').then(module => ({ default: module.ManagePage })))
const MapPage = React.lazy(() => import('./components/Events/Map/MapPage').then(module => ({ default: module.MapPage })))
const TimesPage = React.lazy(() => import('./components/Events/Times/TimesPage').then(module => ({ default: module.TimesPage })))
const PeopleTable = React.lazy(() => import('./components/People/PeopleTable'))
const Units = React.lazy(() => import('./components/Units/UnitsPage'))
const ChampionshipsTable = React.lazy(() => import('./components/Events/Championships/ChampionshipsTable').then(module => ({ default: module.ChampionshipsTable })))
const Metrics = React.lazy(() => import('./components/Metrics/Metrics').then(module => ({ default: module.Metrics })))

const AppRoutes: React.FunctionComponent = () => {
    const identity = useSelector((state: ApplicationState) => state.identity)
    const selectedEventId = useSelector((state: ApplicationState) => state.event.selectedEventId)
    const { isAuthenticated, username } = useSelector((state: ApplicationState) => state.identity)
    const dispatch: Dispatch<any> = useDispatch()

    const [_selectedEventId, _setSelectedEventId] = useState<number>(0)

    useEffect(() => {
        dispatch(identityActionCreators.getIdentity())
    }, [])

    useEffect(() => {
        if (username && isAuthenticated != null)
            dispatch(identityActionCreators.getIdentity())
    }, [username])

    useEffect(() => {
        if (selectedEventId && selectedEventId !== _selectedEventId)
            _setSelectedEventId(selectedEventId)
    }, [selectedEventId, isAuthenticated])

    useEffect(() => {
        if (isAuthenticated == null && _selectedEventId != 0) {
            _setSelectedEventId(0)
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (_selectedEventId != 0) {
            dispatch(mapActionCreators.clearSelectedEntry())
            dispatch(eventActionCreators.requestEventDetails(_selectedEventId))
        }
    }, [_selectedEventId])

    return <Layout isAuthenticated={identity.isAuthenticated}>
        <PubNubContainer selectedEventId={selectedEventId} />
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/events" element={<RequireAuth allowPublic><EventTable username={identity.username} /></RequireAuth>} />
            <Route path="/events-popout" element={<RequireAuth allowPublic><EventTable username={identity.username} /></RequireAuth>} />
            <Route path="/equipment" element={<RequireAuth><LazyRoute component={Equipment} /></RequireAuth>} />
            <Route path="/events/:id/details" element={<RequireAuth allowPublic><LazyRoute component={DetailsPage} /></RequireAuth>} />
            <Route path="/events/:id/entries" element={<RequireAuth allowPublic><LazyRoute component={EntriesPage} /></RequireAuth>} />
            <Route path="/events/:id/itinerary" element={<RequireAuth allowPublic><LazyRoute component={ItineraryPage} /></RequireAuth>} />
            <Route path="/events/:id/manage" element={<RequireAuth><LazyRoute component={ManagePage} /></RequireAuth>} />
            <Route path="/events/:id/times" element={<RequireAuth allowPublic><LazyRoute component={TimesPage} /></RequireAuth>} />
            <Route path="/events/:id/map" element={<RequireAuth allowPublic><LazyRoute component={MapPage} /></RequireAuth>} />
            <Route path="/people" element={<RequireAuth><LazyRoute component={PeopleTable} /></RequireAuth>} />
            <Route path="/units" element={<RequireAuth><LazyRoute component={Units} /></RequireAuth>} />
            <Route path="/championships" element={<RequireAuth><LazyRoute component={ChampionshipsTable} /></RequireAuth>} />
            <Route path="/metrics" element={<RequireAuth><LazyRoute component={Metrics} /></RequireAuth>} />
            <Route path="/message-radar" element={<RequireAuth><LazyRoute component={MessageRadarPage} /></RequireAuth>} />
            <Route path="/accounts" element={<RequireAuth role={CompanyEditor}><LazyRoute component={AccountsPage} /></RequireAuth>} />
            <Route path="/account-settings" element={<RequireAuth role={Basic}><LazyRoute component={AccountSettings} /></RequireAuth>} />
            <Route path='/' element={<Navigate replace to="/events" />} />
            <Route path="/pre-event-form/:formId" element={<LazyRoute component={PreEventForm} />} />
            <Route path='*' element={<Unauthorized />} />
        </Routes>
    </Layout>
}

export default React.memo(AppRoutes)
