import { stagePointError, stagePointWarning } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { IndividualStagePointRule } from '../../../../../store/StageFile'

export const dataValueIsValid: IndividualStagePointRule = (stagePoint) => {
    if (stagePoint.data > 65000)
        return stagePointError(stagePoint, `Data value must be less than 65000 - ${stagePoint.data} is invalid`)
    switch (stagePoint.type.unitPointType) {
        case UnitPointType.TimeControl:
            return stagePoint.data > 255 ? stagePointError(stagePoint, `TC data value must be 255 or less - ${stagePoint.data} is invalid`) : undefined
        case UnitPointType.ChicaneStart:
            return stagePoint.data >= 255 ? stagePointError(stagePoint, `CS data value must be less than 255 - ${stagePoint.data} is invalid`) : undefined
        case UnitPointType.FlyingFinish:
            return stagePoint.data > 255 || stagePoint.data < 40 ? stagePointError(stagePoint, `SF data value must be between 40 and 255 - ${stagePoint.data} is invalid`) : undefined
        case UnitPointType.TransitPoint:
            return stagePoint.data > 255 || stagePoint.data < 40 ? stagePointError(stagePoint, `TP data value must be between 40 and 255 - ${stagePoint.data} is invalid`) : undefined
        case UnitPointType.TransitStart:
            return stagePoint.data > 255 || stagePoint.data < 40 ? stagePointError(stagePoint, `TS data value must be between 40 and 255 - ${stagePoint.data} is invalid`) : undefined
        case UnitPointType.QuietZoneStart:
            return stagePoint.data > 255 ? stagePointError(stagePoint, `QS data value must be less than 255 - ${stagePoint.data} is invalid`) : undefined
        case UnitPointType.QuietZoneFinish:
            return stagePoint.data > 255 || stagePoint.data < 40 ? stagePointError(stagePoint, `QF data value must be between 40 and 255 - ${stagePoint.data} is invalid`) : undefined
        case UnitPointType.ZeroSpeedPoint:
            return stagePoint.data <= 255 ? stagePointError(stagePoint, `ZP data value must be greater than 255 - ${stagePoint.data} is invalid`) : undefined
        case UnitPointType.Waypoint:
            return stagePoint.data < 40 ? stagePointError(stagePoint, `WP data value must be 40 or greater - ${stagePoint.data} is invalid`) : undefined
        case UnitPointType.StageStart:
            return stagePoint.data < 40 || stagePoint.data == 255 ? stagePointWarning(stagePoint, `SS data value must be greater than 255 - ${stagePoint.data} is invalid`) : undefined
        default:
            break
    }
}
