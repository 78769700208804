import { Tooltip } from '@mui/material'
import * as React from 'react'
import { countryList } from '../../components/@Global/CountryList'

const cdnUrl = 'https://racecontrol-cdn.azureedge.net'

interface Props {
    size: number
    code: string
    style?: React.CSSProperties
}

export const CountryFlagIcon: React.FunctionComponent<Props> = (props) => {
    const { size, code, style } = props

    let _code = code

    if (!code || code.isEmpty())
        return null
    if (code.trim() == 'Australia') _code = 'AU'

    let country = countryList.find(x => x.code == code)

    if (code.length == 3) {
        country = countryList.find(x => x.code3 == code)
        if (country) _code = country.code
    }

    if (_code.length != 2)
        return null

    let cdnSize = size != 48 ? size != 32 ? size != 24 ? 64 : size : size : size

    let imagePath = `${cdnUrl}/CountryFlags/${cdnSize}/${_code}.png`
    return <Tooltip title={country?.name ?? ''} placement='bottom'>
        <img
            src={imagePath}
            height={size}
            width={size}
            alt={_code}
            style={{ ...style, height: size, width: size }}
        />
    </Tooltip>
}
