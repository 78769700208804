import * as React from 'react'

interface Props {
    size: number
    path: string
    style: React.CSSProperties
}
const cdnUrl = 'https://racecontrol-cdn.azureedge.net'
export const ManufacturerIcon: React.FunctionComponent<Props> = (props) => {
    const { size, path, style } = props

    if (path === null) return null

    let manu = ''
    if (path.indexOf(' ') != -1) {
        manu = path.substring(0, path.indexOf(' '))
        
        // If year is written first, skip it
        if (parseInt(manu)) {
            manu = path.substring(5, path.indexOf(' ', 5))
        }
    } else {
        manu = path
    }
    manu = manu.toLowerCase()

    let cdnSize = 48

    if (size > 0 && size <= 16) cdnSize = 16
    if (size > 16 && size < 32) cdnSize = 32
    
    let imagePath = `${cdnUrl}/ManufacturerIcons/${cdnSize}/${manu}.png`
    return <img
        style={{ ...style, height: size, width: 'auto' }}
        src={imagePath}
        height={size}
        width={size}
    />
}
